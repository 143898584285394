module.exports = {
  defaultTitle: 'はやしやホルモン館',
  logo: '../../static/favicon_io/favicon-512x512.png',
  author: 'Ryu',
  url: 'https://www.hayashiya-hormone.com',
  legalName: 'はやしやホルモン館',
  defaultDescription: '新鮮なお肉のみを扱う焼肉店',
  socialLinks: {
    twitter: '',
    github: '',
    linkedin: '',
    instagram: '',
    youtube: '',
    google: '',
  },
  googleAnalyticsID: 'UA-88875900-4',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: '',
    twitter: '',
  },
  address: {
    city: 'Nagano-city',
    region: 'Nagano',
    country: 'Japan',
    zipCode: '380-0823',
  },
  contact: {
    email: 'email',
    phone: 'phone number',
  },
  foundingDate: '2020',
}
