import React from 'react'
import { Container } from 'components/common'
import { Wrapper, Flex, Links, Details, Icon } from './styles'
// import social from './social.json'
import monoicon from '../../../assets/icons/Gatsby_Monogram.svg'

export const Footer = () => (
  <Wrapper>
    <Flex as={Container}>
      <Details>
        <span>
          © All rights are reserved | 2020 | Made with{' '}
          <span aria-label="gatsby" role="img">
            <a
              href="https://www.gatsbyjs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon src={monoicon} alt="GatsbyJs" />
            </a>
          </span>{' '}
          by{' '}
          <a
            href="https://twitter.com/ryuk236"
            rel="noopener noreferrer"
            target="_blank"
          >
            Ryu
          </a>
        </span>
      </Details>
      {/*
      <Links>
        {social.map(({ id, name, link, icon }) => (
          <a
            key={id}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`follow me on ${name}`}
          >
            <img width="24" src={icon} alt={name} />
          </a>
        ))}
      </Links>
      */}
    </Flex>
  </Wrapper>
)
